import React, { createContext, useCallback, useEffect, useState } from 'react';
import { InterchangeCompaniesGetList } from '../../api/api-interchange';
import { JobTypesGetList } from '../../api/jobTypes';

export const Context = createContext({});

export const Provider = ({ children }) => {
  const [interchangeCompanies, setInterchangeCompanies] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [jobTypesAll, setJobTypesAll] = useState([]);

  const LoadData = useCallback(async () => {
    setInterchangeCompanies(await InterchangeCompaniesGetList());
    setJobTypes(await JobTypesGetList());
    setJobTypesAll(await JobTypesGetList(false));
  }, []);

  // Make the context object:
  const usersContext = {
    interchangeCompanies,
    jobTypes,
    jobTypesAll,
  };

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  // pass the value in provider and return
  return <Context.Provider value={usersContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
