import React, { createContext, useState, useEffect } from 'react';
import { CurrentUserDetails } from '../../api/auth';
import { claims, today } from '../../config';
import moment from 'moment';

export const Context = createContext({});

export const Provider = ({ children }) => {
  const randomString = () => {
    return Math.random()
      .toString(36)
      .substring(7);
  };

  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState(null);
  const [userClaims, setUserClaims] = useState([]);
  const [imageRandom, setImageRandom] = useState(randomString);
  const [viewingDate, setViewingDate] = useState(today());

  const resetImageRandom = () => {
    setImageRandom(randomString);
  };

  useEffect(() => {
    const LoadData = async () => {
      const currentUser = await CurrentUserDetails();

      setUserName(currentUser.displayName);
      setUserId(currentUser.userId);
      setUserClaims(currentUser.allowed);

      if (!currentUser.allowed.includes(claims.SysAdmin) && !currentUser.allowed.includes(claims.AnyDay)) {
        localStorage.removeItem('TPUNTP-ViewingDate');
      }

      const savedViewingDate = localStorage.getItem('TPUNTP-ViewingDate');

      if (savedViewingDate) {
        setViewingDate(new Date(savedViewingDate));
      }
    };

    LoadData();
  }, []);

  const hasClaim = (claim) => userClaims.includes(claim);

  const isTout = () => {
    return hasClaim(claims.Tout);
  };

  const isBookingAdmin = () => {
    return isSysAdmin() || hasClaim(claims.BookingAdmin);
  };

  const isBoatAdmin = () => {
    return isSysAdmin() || hasClaim(claims.BoatAdmin);
  };

  const isStrideManager = () => {
    return isSysAdmin() || hasClaim(claims.StrideManager);
  };

  const isStrideGuide = () => {
    return isSysAdmin() || hasClaim(claims.StrideGuide);
  };

  const isStrideTout = () => {
    return isSysAdmin() || hasClaim(claims.StrideTout);
  };

  const isMillPondManager = () => {
    return isSysAdmin() || hasClaim(claims.MillPondManager);
  };

  const isMillPondGuide = () => {
    return isSysAdmin() || hasClaim(claims.MillPondGuide);
  };

  const isMillPondTout = () => {
    return isSysAdmin() || hasClaim(claims.MillPondTout);
  };

  const boatCreateClaim = () => {
    return isSysAdmin() || hasClaim(claims.BoatCreate);
  };

  const interchangeClaim = () => {
    return isSysAdmin() || hasClaim(claims.Interchange);
  };

  const isSysAdmin = () => {
    return hasClaim(claims.SysAdmin);
  };

  const anyDayClaim = () => {
    return hasClaim(claims.AnyDay) || hasClaim(claims.SysAdmin);
  };

  const boardClaim = () => {
    return hasClaim(claims.Board) || hasClaim(claims.SysAdmin);
  };

  const dailyNotesClaim = () => {
    return hasClaim(claims.DailyNotes) || hasClaim(claims.SysAdmin);
  };

  const hidePriceClaim = () => {
    return hasClaim(claims.HidePrice);
  };

  const seeToutedPriceClaim = () => {
    return hasClaim(claims.SeeToutedPrice);
  };

  const viewingToday = () => {
    return moment(today()).isSame(viewingDate);
  };

  const back1Day = () => {
    var newDate = new Date(viewingDate);
    newDate.setDate(newDate.getDate() - 1);

    updateViewingDate(newDate);
  };

  const forward1Day = () => {
    var newDate = new Date(viewingDate);
    newDate.setDate(newDate.getDate() + 1);

    updateViewingDate(newDate);
  };

  const goToToday = () => {
    updateViewingDate(today());
  };

  const updateViewingDate = (e) => {
    setViewingDate(e);

    if (moment(today()).isSame(e)) {
      localStorage.removeItem('TPUNTP-ViewingDate');
    } else {
      localStorage.setItem('TPUNTP-ViewingDate', e);
    }
  };

  // Make the context object:
  const loginContext = {
    userId,
    userName,
    userClaims,
    hasClaim,
    imageRandom,
    resetImageRandom,
    isTout,
    isSysAdmin,
    isBookingAdmin,
    isBoatAdmin,
    isStrideManager,
    isStrideGuide,
    isStrideTout,
    isMillPondManager,
    isMillPondGuide,
    isMillPondTout,
    anyDayClaim,
    boardClaim,
    boatCreateClaim,
    dailyNotesClaim,
    hidePriceClaim,
    seeToutedPriceClaim,
    interchangeClaim,
    today,
    viewingDate,
    updateViewingDate,
    viewingToday,
    back1Day,
    forward1Day,
    goToToday,
  };

  // pass the value in provider and return
  return <Context.Provider value={loginContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
