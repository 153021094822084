import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAlignLeft,
  faAlignRight,
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowsAltV,
  faBan,
  faBell,
  faBook,
  faBuilding,
  faCamera,
  faCaretLeft,
  faCaretRight,
  faChartBar,
  faChartPie,
  faCheck,
  faCheckCircle,
  faChessBoard,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClipboard,
  faCloudMoonRain,
  faCopy,
  faCrown,
  faDollarSign,
  faEllipsisH,
  faEraser,
  faExchangeAlt,
  faExternalLinkAlt,
  faEye,
  faFilter,
  faFolder,
  faHeart,
  faHome,
  faLink,
  faListAlt,
  faLock,
  faMinus,
  faPencilAlt,
  faPlay,
  faPlug,
  faPlus,
  faPoundSign,
  faPrint,
  faPuzzlePiece,
  faQuestionCircle,
  faSearch,
  faShareAlt,
  faSignInAlt,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faStickyNote,
  faStream,
  faTag,
  faTimes,
  faTimesCircle,
  faTrash,
  faUser,
  faUserCircle,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons';

import {
  faArrowLeft,
  faArrowRight,
  faBookOpen,
  faBullhorn,
  faCheckSquare,
  faChessClock,
  faChessClockAlt,
  faClock,
  faCog,
  faCommentAlt,
  faCommentAltCheck,
  faCommentAltTimes,
  faExclamation,
  faExclamationCircle,
  faFolders,
  faHouseNight,
  faLightbulbExclamation,
  faPenSquare,
  faPhoneAlt,
  faRectangleWide,
  faSignal2,
  faSignal3,
  faSignOutAlt,
  faSms,
  faSquare,
  faUsers,
  faUserSlash,
  faWalking,
  faWineBottle,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  // Solid
  faChartPie,
  faCopy,
  faSearch,
  faHome,
  faBell,
  faPlay,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faExchangeAlt,
  faAngleLeft,
  faAngleRight,
  faAlignLeft,
  faEllipsisH,
  faCrown,
  faListAlt,
  faCheck,
  faBan,
  faStream,
  faArrowsAltV,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faCheckCircle,
  faUserCircle,
  faQuestionCircle,
  faLock,
  faCircle,
  faHeart,
  faShareAlt,
  faCaretRight,
  faArrowDown,
  faPrint,
  faDollarSign,
  faLink,
  faPlus,
  faMinus,
  faUser,
  faPencilAlt,
  faCamera,
  faTimesCircle,
  faSignInAlt,
  faBook,
  faPuzzlePiece,
  faTrash,
  faAlignLeft,
  faAlignRight,
  faPlug,
  faTimes,
  faFilter,
  faExternalLinkAlt,

  //Pro
  faSignal2,
  faSignal3,
  faBullhorn,
  faPenSquare,
  faExclamationCircle,
  faUsers,
  faCog,
  faSquare,
  faCheckSquare,
  faCommentAlt,
  faExclamation,
  faLightbulbExclamation,
  faUserSlash,
  faClock,
  faChessClock,
  faChessClockAlt,
  faChessBoard,
  faCaretLeft,
  faCaretRight,
  faSquare,
  faStickyNote,
  faClipboard,
  faTag,
  faEye,
  faArrowLeft,
  faArrowRight,
  faChartBar,
  faHouseNight,
  faPoundSign,
  faExchangeAlt,
  faEraser,
  faFolder,
  faFolders,
  faCommentAltCheck,
  faCommentAltTimes,
  faSms,
  faSignOutAlt,
  faCloudMoonRain,
  faBuilding,
  faWalking,
  faPhoneAlt,
  faUtensils,
  faWineBottle,
  faRectangleWide,
  faBookOpen
);
