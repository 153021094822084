import axios from 'axios';
import { today } from '../config';
import { formattedDate } from '../helpers/dateHelpers';

export const InterchangeCompaniesGetList = async () => {
  try {
    const result = await axios({
      method: 'get',
      url: '/v1/InterchangeCompanies',
      params: {},
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const InterchangeSave = async (id, userId, date, amount, interchangeCompanyId, notes) => {
  if (!date) {
    date = new today();
  }
  date = formattedDate(date);

  try {
    const result = await axios({
      method: 'post',
      url: 'v1/Interchange',
      data: {
        id,
        userId,
        date,
        amount,
        interchangeCompanyId,
        notes,
      },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const InterchangesGet = async (date) => {
  if (!date) {
    date = new today();
  }

  date = formattedDate(date);

  try {
    const result = await axios({
      method: 'get',
      url: 'v1/Interchanges',
      params: { date },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const InterchangeGet = async (id) => {
  try {
    const result = await axios({
      method: 'get',
      url: 'v1/Interchange',
      params: { id },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};
